@import '@angular/cdk/overlay-prebuilt.css';
@import './assets/base.css';

html {
    height: 100%;

}

body {
    min-height: 100%;
    height: 100%;
    font-family: Verdana, Arial, Helvetica, Geneva, sans-serif;
    font-size: 14px;
    margin: 0;
    padding: 0;
    background-color: #497EDC;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #b0b0b0 #ebebeb;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 13px;
}

*::-webkit-scrollbar-track {
    background: #ebebeb;
}

*::-webkit-scrollbar-thumb {
    background-color: #b0b0b0;
    border-radius: 8px;
    border: 2px solid #ebebeb;
}

.ignore-css{all:unset;}

.roundCorners {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.noSelection {
    -ms-user-select: None;
    -moz-user-select: None;
    -webkit-user-select: None;
    user-select: None;
}

.marked
{
    background-color: #fff0d4 !important;
}
.odd
{
    border-left: 3px solid #001875 !important;
}
.even
{
    border-left: 3px solid #40b5ff !important;
}

.mark
{
    border-left: 3px solid orange !important;
}

.first
{
    border-bottom:1px solid #636363 !important;
}

.modul_selector
{
    width: fit-content;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 12px;
    background: none;
    color: #ffffff;
    font-family: Verdana, Arial, Helvetica, Geneva, sans-serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    height: auto !important;
}
.modul_selector:focus { outline: none; }
.modul_selector option { color: #000000; line-height: 1.2em; }

.symbi .dijitBorderContainer-dijitContentPane
{
    background: none;
}

.selected tr, .selected
{
    background-color: #fff0d4 !important;

}

.hover:hover
{
    background-color: #e5e5e5;
}


.arrow-up {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;

    border-bottom: 15px solid white;
}

.cpMenuPanelOpen
{
    overflow:hidden;
    visibility:visible;
    color: #000000;
    top:0px; bottom:0px;
    right:0px; left:0px;
    position: fixed;
    z-index: 9999999;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-image: url('/design/site/menuLogo.png');
    background-color: rgb(73, 126, 220);
}

.cpMenuPanel
{
    position: fixed;
    visibility: hidden;
    top:0px; bottom:0px;
}


.cpMenuModulBar {
    position: absolute;
    left:0px;
    top:0px;
    right:0px;
    border-bottom: 1px solid #767676;
    background: #ffffff;
}

.tri-down {
    /* Styling block element, not required */
    position: absolute;
    left:-50px;
    top:29px;
    right:0px;
    margin-bottom: 2em;

    background: #ffffff;
}

/* Required for Down Triangle */
.tri-down:before, .tri-down:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-bottom: 0;
}

/* Stroke */
.tri-down:before {
    bottom: -16px;
    left: 0px;
    border-top-color: #777;
    border-width: 16px;
}

/* Fill */
.tri-down:after {
    bottom: -15px;
    left: 1px;
    border-top-color: #ffffff;
    border-width: 15px;
}

.cpMenuModuleButton
{
    display: inline-block;
    font-size: 1em;
}


.cpMenuModuleButton:hover
{
    text-shadow: 1px 1px 1px black;
}

.cpMenuModuleButton span , .cpMenuModuleButtonActive span
{
    margin: 6px 40px ;
    display: inline-block;
}

.cpMenuButton
{
    height: 25px;
    border-bottom: 1px solid #a7a7a7;
}

.cpMenuButton span
{
    margin-left:20px;
    margin-top:5px;
    display: inline-block;
}

.cpMenuButton.devider
{
    background-color: #484848;
    color: white;
}

.cpMenuButton.devider:hover
{
    background-color: #484848;
}

.cpMenuButton:hover
{
    background-color: #eeeeee;
}

.cpBox
{
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 2px solid rgb(52, 84, 174);
    background: #ffffff;
    position: absolute;
    left:20px;
    top:50px;
    width:220px;
    padding-bottom: -2px;
    border-bottom: 4px solid rgb(52, 84, 174);
}

.cpBoxHead
{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 2px solid rgb(52, 84, 174);
    width: 100%;
    height: 27px;
    background-color: rgb(52, 84, 174);
    color: white;
}

.cpBoxHeadText
{
    display:inline-block;
    margin-left: 20px;
    margin-top: 3px;
}


.infoCenterPanel
{
    position: fixed;
    visibility: hidden;
    top:0px; bottom:0px;
}



.infoCenterPanelOpen
{
    overflow:hidden;
    visibility:visible;
    color: #000000;
    top:0px;
    left:0px;
    width:500px;
    min-height: 20px;
    position: fixed;
    z-index: 9999999;
    background: white;
    border:1px solid #b1b1b1;
}

.cpuiClosePanelOverlay
{
    position: fixed;
    visibility: hidden;
    top:0px; bottom:0px;
}

.cpuiClosePanelOverlayOpen
{
    overflow:hidden;
    visibility:visible;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    position: fixed;
    z-index: 9999998;
    background: none;
}


.as-split-gutter{ background-color: rgb(73, 126, 220) !important; width : 3px!important; flex-basis:6px !important;}
.as-split-gutter:hover { background-color: #fff0d4 !important; ; }



#sidebarContainerDefinition {
    position:relative;
    width:230px;
    height: calc(100% - 31px);
    float: left;
}

.spaceForAdminMenu
{
    width:calc(100% - 235px);
    float: right;
}

#sidebarContainerBox
{
    width:100%;
    height: 100%;
    position: absolute;
    border-radius: 0px 0px 7px 7px;
    -moz-border-radius: 0px 0px 7px 7px;
    -webkit-border-radius: 0px 0px 7px 7px;
}
#sidebarContainerTop
{
    color: white;
    background-color: rgb(52, 84, 174);
    font-size: 0.9em;
    position: relative;
    display: block;
    height: 30px;
    right: 0;
    left: 0;
    top: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 7px 7px 0px 0px;
    -moz-border-radius: 7px 7px 0px 0px;
    -webkit-border-radius: 7px 7px 0px 0px;
}

#sidebarContainerContent
{
    background-color: #ffffff;
    position: relative;
    height:100%;
    overflow: auto;
    border: 3px solid #3454AE;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 0px 0px 7px 7px;
    -moz-border-radius: 0px 0px 7px 7px;
    -webkit-border-radius: 0px 0px 7px 7px;
}



#leftContainerDefinition {
    height:99.9%;
    position:relative;
}

#leftContainerTop .data_tabel {
    font-size: 10px;
    width: 100%;
    margin-top: 8px;
}

#leftContainerTop .listCounter
{
    float: right;margin-right:10px; margin-top:6px;
}

#leftContainerTop .currentLocation
{
    margin-top: 8px;
    margin-left: 10px;
    margin-bottom: -2px;
    display: block;
}

#leftContainerTop .search {
    margin-top: 3px;
    margin-left: 10px;
    display: block;
}

#leftContainerTop
{
    color: white;
    background-color: rgb(52, 84, 174);
    font-size: 0.9em;
    position: absolute;
    display: block;
    height: 150px;
    right: 0;
    left: 0;
    top: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 7px 7px 0px 0px;
    -moz-border-radius: 7px 7px 0px 0px;
    -webkit-border-radius: 7px 7px 0px 0px;
}

#leftContainerContent
{
    background-color: #ffffff;
    position: absolute;
    top:80px;
    left: 0;
    bottom:34px;
    right: 0px;
    overflow: auto;
    border: 3px solid #3454ae;
    border-top: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

#leftContainerBottom, #rightContainerBottom
{
    color: white;
    background-color: rgb(52, 84, 174);
    position: absolute;
    height: 34px;
    bottom: 0;
    border: 3px solid #3454AE;
    left: 0px;
    right: 0px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 0px 0px 7px 7px;
    -moz-border-radius: 0px 0px 7px 7px;
    -webkit-border-radius: 0px 0px 7px 7px;
}



#rightContainerDefinition {
    height:99.9%;
    position:relative;
}


#rightContainerTop
{
    color: white;
    background: #3454ae !important;
    display: block;
    height: 150px;
    border-radius: 7px 7px 0px 0px;
    -moz-border-radius: 7px 7px 0px 0px;
    -webkit-border-radius: 7px 7px 0px 0px;
    left: 0px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 0.9em;
    border: 3px solid #3454ae;
    background: rgb(255,255,255); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(247,247,244,1) 5%, rgba(247,247,244,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(247,247,244,1) 5%,rgba(247,247,244,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(247,247,244,1) 5%,rgba(247,247,244,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f7f7f4',GradientType=0 ); /* IE6-9 */
}

#rightContainerContent
{
    left: 0px;
    position: absolute;
    right: 0;
    top: 150px;
    overflow: auto;
    border: 3px solid #3454AE;
    border-top: 0px;
    background-color: #ffffff;
}

#rightContainerContent .inlineForm                     { margin:10px; }

#rightContainerContent .inlineForm .inlineFormCaption  { margin-left:10px; display: block; font-size: 11px;}

.headerNone #rightContainerContent, .headerNone #leftContainerContent { top: 0px;}

.header1Row #rightContainerContent, .header1Row #leftContainerContent { top: 32px;}

.header2Row #rightContainerContent, .header2Row #leftContainerContent { top: 55px;}

.header3Row #rightContainerContent, .header3Row #leftContainerContent { top: 80px;}

.header4Row #rightContainerContent, .header4Row #leftContainerContent { top: 106px;}

.header5Row #rightContainerContent, .header5Row #leftContainerContent { top: 138px;}

.header6Row #rightContainerContent, .header6Row #leftContainerContent { top: 170px;}

.header7Row #rightContainerContent, .header7Row #leftContainerContent { top: 202px;}

.footerNone #rightContainerContent, .footerNone #leftContainerContent
{
    bottom: 0px !important;
    border-radius: 0px 0px 7px 7px;
    -moz-border-radius: 0px 0px 7px 7px;
    -webkit-border-radius: 0px 0px 7px 7px;
}

.footer1Row #rightContainerContent, .footer1Row #leftContainerContent { bottom:30px !important;}

#fullscreenForm
{
    background-color: #f0f0f0;
    position: absolute;
    top:0px;
    left: 0;
    min-height: 100px;
    width: 1000px;
    overflow: hidden;
    border: 1px solid #858585;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 7px 7px 7px 7px;
    -moz-border-radius: 7px 7px 7px 7px;
    -webkit-border-radius: 7px 7px 7px 7px;
}

.formPadding tr td{
    padding: 4px;
}
.form {
    color: black;
}

.form .dark{
    color: white;
}

.form .formCaption
{
    font-size:11px;
    margin-left:6px;
}


.form input[type=text], .form input[type=password], .form input[type=time], .form input[type=number], .form textarea {
font-weight: revert;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
    border: 1px solid #ceced2;
    background-color: #ffffff;
    color: #000;
    height:20px;
    font-size: 12px;
    padding: 1px .2em;
    display: inline-block;
    vertical-align: top;
    margin-right:4px;
}

.form input[type=text]:focus,
.form input[type=password]:focus,
.form input[type=time]:focus,
.form button:focus,
.form textarea:focus
{
    border: 1px solid #727284;
}

.form input[disabled]
{
    background-color: #ebebf0 !important;
}

*:focus
{    outline-style: none;}



.form > button {
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
    font-size: 12px !important;
    border: 1px solid #b5b2b2;
    background-color: #fafafa;
    color: #242424 !important;
    height: 2.0em !important;
    padding: 2px .3em !important;
    display: inline-block !important;
    vertical-align: top !important;
    margin: 0px 2px 0px 0px;
    min-width: 100px;
}


.form > button:disabled {
    background-color: #bdbdbd;
    filter: blur(1px);
    color: #767676 !important;
}

.form editor button {
    border:  unset !important;
    background-color: unset !important;
    min-width: unset !important;
}

.form button img
{
    vertical-align: bottom;
}

.form button:hover
{
    background-color: #e5e5e5 !important;
    border: 1px solid #848484 !important;
}

.form select
{
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
    border: 1px solid #ceced2;
    background-color: #ffffff;
    color: #000 !important;
    height:24px;
    text-indent: .33em !important;
    line-height: 1.6em !important;
    margin-right:4px;
    font-size: 12px;
}

.form select[disabled]
{
    background-color: #ebebf0 !important;
}

.form select:focus
{
    border: 1px solid #727284;
}

.error {
    border-left: 3px solid orange !important;
    background-color: #fff0d4 !important;
}


.error iframe {
    background-color: #fff0d4 !important;
}


.dropdown-menu { background-color: #ffffff; border:1px solid #cfcfcf; padding: 0px; margin: 0px;}
.dropdown-menu  li {background-color: #ffffff;list-style-type: none;}
.dropdown-menu .divider {list-style-type: none; height:1px;background-color: #cfcfcf; }
.dropdown-menu  li span {display: inline-block;width:100%; margin: 4px 8px;}
.dropdown-menu  li:hover {background-color: #fff0d4;}


.blueRoundButton {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #3454AE;
    min-width: 38px;
    height: 28px;
    display: inline-block;
    border: none;
    vertical-align: middle;
    color:  #ffffff;
    text-align: left;
}

.hoverItem div:hover { background-color: #e5e5e5;cursor:pointer; }

.ng-modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
}

.ng-modal-dialog {
    position: absolute;
    top: 30%;
    left: 50%;
    z-index: 999;
    width: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0 0 60px rgba(0, 0, 0, .4);
}

.ng-modal-dialog-head {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    right:0;
    height: 25px !important;
    color: #fff;
    background: rgb(52, 84, 174);
}

.ng-modal-dialog-head span {
    display: inline-block;
    margin-top: 4px;
    margin-left: 4px;
}
.ng-modal-close {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 9999;
    cursor: pointer;
    font-size: 120%;
    display: inline-block;
    font-family: 'arial', 'sans-serif';
    font-weight: bold;
    color: #c6c6c6;

}

.bold{
    font-weight: bold;
}

.ng-modal-dialog-content {
    padding:20px;
    text-align: left;
}

.tooltip {
    display: none;
    position: absolute;
    background-color: #ffffff;
    padding: 7px 10px;
    max-width: 250px;
    color: #000000;
}

.tooltip-show {
    display: inherit;
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
}

.tooltip-down .tooltip-arrow {
    top: -5px;
    left: 50%;
    margin-left: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #ffffff;
}

.tooltip-up .tooltip-arrow {
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #ffffff;
}

.tooltip-right .tooltip-arrow {
    left: -5px;
    top: 50%;
    margin-top: -3px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #ffffff;
}

.tooltip-left .tooltip-arrow
{
    right: -5px;
    top: 50%;
    margin-top: -3px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #ffffff;
}

.tab, .form .tab
{
    border-radius: 3px 3px 0px 0px;
    -moz-border-radius: 3px 3px 0px 0px;
    -webkit-border-radius: 3px 3px 0px 0px;
    font-size: 12px !important;
    border: 1px solid #a1a1a2 !important;
    background-color: #d3d3d4 !important;
    color: #242424 !important;
    height: 18px !important;
    display: inline-block !important;
    vertical-align: top !important;
    margin: 0;
    margin-top: 11px;
    padding-right: 6px !important;
    padding-left: 6px !important;
    padding-bottom: 4px !important;
    border-bottom: 1px solid #666666 !important;
}

 .tab_selected, .form .tab_selected
{
    height: 22px !important;
    background-color: #ffffff !important;
    margin-top: 8px;
    vertical-align: top;
    padding-bottom: 0px !important;
    border-bottom: 1px solid #ffffff !important;
}

.card
{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
    border: 1px solid #dbdbdb;
    background-color: white;
}

.card .header
{
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px solid darkgray;
}

.card .header .info
{
    color: #3862AE;
    font-size: 0.8em;
    font-weight: bold;
    display: block;
}

.card .header .info img
{
    width:16px; height:16px; vertical-align:sub;
    margin-bottom: -1px;
}

.card .header .title
{
    display: block;
    margin-top: 3px;
    margin-bottom: 3px;
    font-weight: bold;
}

.card .text
{
    margin: 20px;
    margin-top: 10px;
    font-size: 0.9em;
}

.card .text pre
{
    white-space: break-spaces;
}

.shadow {
    -webkit-box-shadow: 10px 10px 25px -4px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 10px 10px 25px -4px rgba(0, 0, 0, 0.37);
    box-shadow: 10px 10px 25px -4px rgba(0, 0, 0, 0.37);
}

.processEntry .header
{
    display: block;
    width: 97%;
    margin-left: 15px; margin-right: 10px; margin-top: 10px;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 3px;
}

.processEntry .header .info
{
    display: inline-block;
    margin-top: 15px;
    font-size: 0.8em;
    color:#3862AE;
    font-weight: bold;
}


.processEntry .header .title
{
    font-weight: bold;
    margin-top: 3px;
}

.processEntry .header img {vertical-align:middle; margin-top:-1px; margin-right: 8px;}

.processEntry .text
{
    all: unset;
    display: block;
    width: 97%;
    margin-left: 15px; margin-top: 10px;
}

.processEntry .text img
{
    max-width:100% !important;
}


.data_tabel {
    font-size: 11px;
    border-collapse: collapse;
    margin: 0;
}

.data_tabel .data_table_seperator {
    text-align: center;
    background: #fff;
    border-bottom: 1px solid #000000;
    height: 16px;
    font-weight: bold;
}

.data_tabel .data_table_seperator:hover {
    background: #fff !important;
    color: #000 !important;
}

.data_tabel .head {
    border-bottom: none;
}

.data_tabel th {
    color: #FFF;
    font-variant: small-caps;
    height: 12px;
    background-color: #3454AE;
}

.data_tabel th:last-child {
    border-right: none;
}

.data_tabel .no_right_border {
    border-right: none;
}

.data_tabel .scroller_col {
    width: 8px;
}

.data_tabel th a {
    text-decoration: none;
    color: #000000;
}

.data_tabel table th, td, tr {
    /* CSS Document subtabels clean all styles */
    background-image: none;
    background-color: transparent;
}

.data_tabel td {
    background-color: #FFFFFF;
    height: 16px;
    border-right: 1px solid #CCDCEE;
    border-bottom: 1px solid #CCC;
    border-top: 1px solid transparent;
}

.data_tabel td:last-child {
    border-right: none;
}

.data_tabel tr:hover > td {
    background-color: #eeeeee;
    background-image: none;
    color: #000000;
}

.data_tabel tr.selected td {
    background-color: #fff0d4 !important;
    border-bottom: 1px solid orange;
}

.data_tabel tr.selected td table tr td table tr td {
    background-color: white !important;
}

.data_tabel tr.selected td table tr td table tr .green {
    background-color: rgb(0, 160, 0) !important;
}
.data_tabel tr.selected:hover > td {
    background-color: #fff0d4;
    color: #000000;
}

.data_tabel td a:link {
    text-decoration: none;
    color: #000000;
}

.data_tabel td a:visited {
    text-decoration: none;
    color: #000000;
}

.data_tabel td a:active {
    text-decoration: none;
    color: #000000;
}

.data_tabel td a:hover {
    text-decoration: none;
    color: #000000;
}

.data_tabel tr:hover > td a {
    color: #000000;
}

.data_tabel img {
    margin-right: 2px;
    margin-left: 2px;
}


.data_tabel table td {
    /* CSS Document subtabels clean all styles */
    background-image: none;
    background-color: transparent;
}

.pointer tr {
    cursor: pointer;
}

.light th {
    border-bottom: rgba(0, 0, 0, 0.56) 1px solid;
    color: #000 !important;
    height: 12px;
    font-size: 11px;
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(224,224,224,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(224,224,224,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(224,224,224,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#e0e0e0',GradientType=0 ); /* IE6-9 */

}

.light tr {
    background-color: white;
}

.data_tabel .darker {
    background-color: #666;
    color: #FFFFFF;
}

.data_tabel .card {
    background-color: #ffffdf;
    color: #000000;
    border: 1px solid #cdcdcd;
}


.data_tabel .kat {
    background-color: #497EDC;
    color: #ffffff;
    border: none;
    border-left: 1px solid #FFF;
    border-right: 1px solid #FFF;
    border-top: 1px solid #FFF;
}

.data_tabel .kat span {
    color: #ffffff;
    font-weight: bold;
    font-variant: small-caps;
    margin-left: 4px;

}

.easy_table {
    border-collapse:collapse;
    border: 1px solid lightgrey;
}

.easy_table td{
    border-collapse:collapse;
    border: 1px solid lightgrey;
}

.round_button {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #3454AE;
    width: 38px;
    height: 28px;
    display: inline-block;
    /*border:1px solid #DEDEDE;*/
    vertical-align: middle;
    min-width: 10px !important;
    padding: 0px; border: none; margin:0px;
    color: white;
    font-size: 16px;
    vertical-align: center;
}
.round_button span{
    margin-left: 8px;
    margin-right: 10px;
     display: inline-block;
    vertical-align: sub;
    margin-bottom: 2px;
 }

.round_button img{
    vertical-align: middle;
}





.freeContainerDefinition {
    height:99.9%;
    position:relative;
}

.freeContainerTop .data_tabel {
    font-size: 10px;
    width: 100%;
    margin-top: 8px;
}

.freeContainerTop .listCounter
{
    float: right;margin-right:10px; margin-top:6px;
}

.freeContainerTop .currentLocation
{
    margin-top: 8px;
    margin-left: 10px;
    display: block;
}

.freeContainerTop .search {
    margin-top: 3px;
    margin-left: 10px;
    display: block;
}

.freeContainerTop
{
    color: white;
    background-color: rgb(52, 84, 174);
    font-size: 0.9em;
    position: absolute;
    display: block;
    height: 150px;
    right: 0;
    left: 0;
    top: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 7px 7px 0px 0px;
    -moz-border-radius: 7px 7px 0px 0px;
    -webkit-border-radius: 7px 7px 0px 0px;
}

.freeContainerContent
{
    color: black;
    background-color: #ffffff;
    position: absolute;
    top:80px;
    left: 0;
    bottom:34px;
    right: 0px;
    overflow: auto;
    border: 3px solid #3454AE;
    border-top: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.freeContainerContent .data_tabel
{
    margin-top: 0 !important;
}

.freeContainerBottom
{
    color: white;
    background-color: rgb(52, 84, 174);
    position: absolute;
    height: 34px;
    bottom: 0;
    border: 3px solid #3454AE;
    left: 0px;
    right: 0px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 0px 0px 7px 7px;
    -moz-border-radius: 0px 0px 7px 7px;
    -webkit-border-radius: 0px 0px 7px 7px;
}



.freeContainerContent .inlineForm                     { margin:10px !important; }

.freeContainerContent .inlineForm .inlineFormCaption  { margin-left:10px !important; display: block !important; font-size: 11px !important;}

.freeContainerDefinition .headerNone .freeContainerContent  { top: 0px !important;}

.freeContainerDefinition .header1Row .freeContainerContent { top: 32px !important;}

.freeContainerDefinition .header2Row .freeContainerContent  { top: 55px !important;}

.freeContainerDefinition .header3Row .freeContainerContent  { top: 80px !important;}

.freeContainerDefinition .header4Row .freeContainerContent  { top: 106px !important;}

.freeContainerDefinition .footerNone .freeContainerContent
{
    bottom: 0px !important;
    border-radius: 0px 0px 7px 7px !important;
    -moz-border-radius: 0px 0px 7px 7px !important;
    -webkit-border-radius: 0px 0px 7px 7px !important;
}

.freeContainerDefinition .footer1Row .freeContainerContent { bottom:30px !important;}

.tooltipContent {
padding-bottom: 5px;
}

.tooltipContent span, #leftContainerTop .tooltipContent span {
    display: block;
    font-size: 1.4em !important;
    margin-bottom: 5px !important;
    margin-right: 5px;
    margin-top: 10px;
}

.tox-mbtn {
 height:22px !important;
}

.tox-menubar {
 height:26px !important;
}

.tox .tox-toolbar__primary {
    border-bottom: 1px solid lightgrey !important;
}

.tox .tox-tbtn {
    height:24px !important;
    width: 24px !important;
}
.tox .tox-tbtn--select {
    width: fit-content !important;
}

.tox-tinymce {
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important;
}

.tox-editor-header, .tox-statusbar
{
    background: #f9f9f9 !important;
}
.tox .tox-menubar, .tox .tox-toolbar__primary, .tox  .tox-toolbar-overlord{
    background: unset !important;
    background-color: unset !important;
}

.tox-tinymce {
    height:100% !important;
    min-height: 200px !important;
}

.new-tooltip-class  {
    left:50px !important;
    max-width: 500px !important;
}


.new-tooltip-class div {
    text-align: left;
    font-size: 13px;
}

.new-tooltip-class div div {
    white-space: nowrap;
    display: inline-block;
}
.new-tooltip-class div b {
    font-size: 1.3em;
}


.attachmentBox
{
    background-color: #e9e9e9;
    border: 1px solid darkgray;
    display: inline-block;
    margin-bottom: 3px;
    height: 28px;
    min-width: 150px;
    font-size: 0.8em;
    overflow: hidden;
    white-space: nowrap;
}

.attachmentBox:hover
{
    background-color: #fff0d4;
}

.attachmentBox .attachmentBoxImage {
    display: inline-block;
    width:28px; height:28px;
    border-right: 1px solid darkgray;
    vertical-align: top;
}

.attachmentBox .attachmentBoxImage img {
    margin-left: 6px;
    margin-top: 6px;
}

.attachmentBox .attachmentBoxText
{
    display: inline-block;
    display: inline-block;
    width: calc(100% - 28px); height:28px;
    margin-left: 6px;
    margin-right: 6px;
}

.tooltip {
    z-index: 99999999 !important;
    background-color: white !important;
    -webkit-box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.64) !important;
    -moz-box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.64) !important;
    box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.64) !important;
    color: black !important;
    text-align: left !important;
}

.tooltip-right::after {
    visibility: hidden;
}

.tooltip-left::after {
    visibility: hidden;
}

.tooltip-top::after {
    visibility: hidden;
}

.tooltip-bottom::after {
    visibility: hidden;
}


.itemBox {
    display: inline-block;
    background-color: #eaeaea;
    border-radius: 4px 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
    border: 1px solid #b8b7b7;
    width:fit-content;
    padding-left:4px;
    padding-right:2px;
    height:22px;
    margin-right:5px;
    margin-left: 1px;
    vertical-align: top;
    margin-bottom: 6px;
}

.itemBox .del{
    border-left:1px solid gray;
    float: right;
    height:22px;
}

.itemBox .del img{
    margin-right:4px;
    margin-left:4px;
    vertical-align: middle;
}

.itemBox .del:hover{
    background-color:  #fff0d4;
    cursor: pointer;
}

.itemBox .edit{
    height:20px;
    float: left;
    padding-left: 4px;
    padding-right: 4px;
}

.itemBox .show{
    height:18px;
    float: left;
    padding-left: 4px;
    padding-right: 8px;
    vertical-align: text-bottom;
    padding-top: 4px;
    font-size: 12px;
}

.itemBox .show img{
    width:15px;
    height:15px;
}

.itemBox .show span{
    vertical-align: text-bottom;
}

.itemBox .show input{
    margin-top: -5px;
    width: 100%;
    margin-right: 0px !important;
}

.itemBox .show:hover{
    background-color:  #fff0d4;
    cursor: pointer;
}

.itemBox img{
    vertical-align: text-bottom;
    width:12px;
    height: 12px;
}

    .blinkingWhiteToRed{
        animation:blinkingWhiteToRedText 2.2s infinite;
    }
    @keyframes blinkingWhiteToRedText{
        0%{     color: white;    }
        20%{     color: orange;    }
        40%{    color: white; }
        100%{   color: white;    }
    }

    .dialog {
        display: block;
        border: 1px solid #b6b6b6;
        border-radius: 4px 4px 4px 4px;
        -moz-border-radius: 4px 4px 4px 4px;
        -webkit-border-radius: 4px 4px 4px 4px;
    }

    .dialogHeader {
        border-radius: 4px 4px 0px 0px;
        -moz-border-radius: 4px 4px 0px 0px;
        -webkit-border-radius: 4px 4px 0px 0px;
        width: 100%;
        height: 30px;
        background-color: lightgray;
        border-bottom: 1px solid darkgray;
    }

    .dialogHeader div {
        margin-left: 8px;
        display: inline-block;
        margin-top: 8px;
    }

    .dialogHeader span {
        font-weight: bold;
        vertical-align: top;
    }

    .dialogContent {
        width: fit-content;
        height: fit-content;
        margin: 20px;
    }

    .dialogFooter {
        width: 100%;
        height: 30px;
        background-color: rgb(35, 52, 98);
        border-radius: 0px 0px 4px 4px;
        -moz-border-radius: 0px 0px 4px 4px;
        -webkit-border-radius: 0px 0px 4px 4px;
    }

.jw-modal {
    overflow: hidden !important;
}

.jw-modal .jw-modal-background {
    opacity: 0.55 !important;
}
.roundCorner {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

option {
    zoom: 1.15;
}


.ngx-contextmenu .ngx-context-menu-item[role=menuitem] {
    justify-content: flex-start !important;
    padding: 0 !important;
}

.ngx-contextmenu .ngx-context-menu-item[role=separator] {
    width:100% !important;
    margin: 0 !important;
}

.form .active {
    background-color: #cdffb3 !important;
}
.form .active:hover {
    background-color: #ace38f !important;
}


.cdk-drag-preview {
    width: 100%;
    cursor: move;
    border: 1px solid gray !important;
    background-color: rgba(255, 255, 255, 0.8) !important;
    -webkit-box-shadow: 10px 10px 25px -4px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 10px 10px 25px -4px rgba(0, 0, 0, 0.37);
    box-shadow: 10px 10px 25px -4px rgba(0, 0, 0, 0.37);
}
.cdk-drop-custom-placeholder {
    background: #ccc;
    border: dotted 2px #6b6b6b;
    position: absolute;
    width:100%;
    min-height: 20px;
    height: 20px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dontShowDropElement .cdk-drag-placeholder {
    display:none;
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
    transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
}

.pathSelector {
    display: inline-block;
}

.pathSelector select option {
    font-size: 11px;
}

.pathSelector:hover {
    background-color: #497EDC;
    cursor: pointer;
}

.articleData input[type=text], .articleData input[type=number], .articleData select, .articleData textarea {
    border: 1px solid white !important;
    font-weight: revert;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
    background-color: #ffffff;
    color: #000;
    height:20px;
    font-size: 12px;
    padding: 1px .2em;
    display: inline-block;
    vertical-align: top;
    margin-right:4px;
}



.articleData input[type=text]:focus, .articleData input[type=number]:focus, .articleData select:focus, .articleData textarea:focus  {
    border: 1px solid white !important;
    border-bottom: 1px solid orange !important;
}